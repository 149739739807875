import React, {useState} from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from 'gatsby';
import RequestDemoModal from "./RequestDemoModal";
import {downloadAppStoreUrl} from '../../constants/layout';
import ReactGA from "react-ga4";

const mavsignIcon: string = require('assets/images/mavsign.svg').default;
const linkedinIcon:string = require('assets/images/linkedin.svg').default;
const facebookIcon: string = require('assets/images/facebook.svg').default;
const appStoreIcon: string = require('assets/images/app-store-btn.svg').default;
const line: string = require('assets/images/line.svg').default;
const starIcon: string = require('../../assets/images/star-icon.svg').default;

const Footer = () => {
  // STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleRequestDemoClick = () => {
    // Track the event when the "Request Demo" button is clicked
    ReactGA.event({
      category: 'Button',
      action: 'request_demo_event_footer',
      label: 'Request Demo Button - Footer'
    });
    
    setIsModalOpen(true);
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row className="desktop mb-4">
            <Col xs={6} md={6}>
              <Link to="/" className="mb-3 footer-link" activeClassName="footer-link-active">Company</Link>
              <Link to="/news" className="mb-3 footer-link" activeClassName="footer-link-active"><img src={starIcon} alt="star" className="star-icon" /> What's New?</Link>
              <Link to="/dealers" className="mb-3 footer-link" activeClassName="footer-link-active">For Dealers</Link>
              <Link to="/services" className="mb-3 footer-link" activeClassName="footer-link-active">Services</Link>
              <Link to="/pricing" className="mb-3 footer-link" activeClassName="footer-link-active">Prices</Link>
              <Link to="/notaries" className="mb-3 footer-link" activeClassName="footer-link-active">For Notaries</Link>
            </Col>
            <Col xs={6} md={4}>
              <Link to="#faqs" className="mb-3 footer-link" activeClassName="footer-link-active">FAQs</Link>
              <Link to="/contact-us" className="mb-3 footer-link" activeClassName="footer-link-active">Contact us</Link>
              <Link to="/privacy-policy" className="mb-3 footer-link" activeClassName="footer-link-active">Privacy Policy</Link>
              <Link to="/terms-and-conditions" className="mb-3 footer-link" activeClassName="footer-link-active">Terms & Conditions</Link>
            </Col>
            <Col xs={12} md={2}>
              <button onClick={handleRequestDemoClick} className='btn btn-primary request-demo-btn mb-4'>Request Demo</button>
              <a href={downloadAppStoreUrl.url} target="_blank" rel="noopener noreferrer">
                <img className="download-app-store" src={appStoreIcon} alt="Download App Store" />
              </a>
            </Col>
          </Row>

          <Row className="mobile mb-4">
            <Col xs={6} md={6}>
              <Link to="/" className="mb-3 footer-link" activeClassName="footer-link-active">Company</Link>
              <Link to="/services" className="mb-3 footer-link" activeClassName="footer-link-active">Services</Link>
              <Link to="/dealers" className="mb-3 footer-link" activeClassName="footer-link-active">For Dealers</Link>
              <Link to="/notaries" className="mb-3 footer-link" activeClassName="footer-link-active">For Notaries</Link>
              <Link to="/news" className="mb-3 footer-link" activeClassName="footer-link-active"><img src={starIcon} alt="star" className="star-icon" /> What's New?</Link>
              <Link to="/pricing" className="mb-3 footer-link" activeClassName="footer-link-active">Prices</Link>

            </Col>
            <Col xs={6} md={4}>
              <Link to="#faqs" className="mb-3 footer-link" activeClassName="footer-link-active">FAQs</Link>
              <Link to="/privacy-policy" className="mb-3 footer-link" activeClassName="footer-link-active">Privacy Policy</Link>
              <Link to="/contact-us" className="mb-3 footer-link" activeClassName="footer-link-active">Contact us</Link>
              <Link to="/terms-and-conditions" className="mb-3 footer-link" activeClassName="footer-link-active">Terms & Conditions</Link>
            </Col>
            <Col xs={12} md={2}>
              <button onClick={handleRequestDemoClick} className='btn btn-primary request-demo-btn mb-4'>Request Demo</button>
              <a href={downloadAppStoreUrl.url} target="_blank" rel="noopener noreferrer">
                <img className="download-app-store" src={appStoreIcon} alt="Download App Store" />
              </a>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} className="social-media d-flex justify-content-center my-3 mb-4">
              <img src={line} className="footer-lines" alt="line"/>
              <p className="follow-label">Follow us:</p>
              <a href="https://www.linkedin.com/company/mavsign/" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="linkedin" className="mx-3"/>
              </a>
              <a href="https://www.facebook.com/mavsign/" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="facebook" className="me-3"/>
              </a>
              <img src={line} alt="line" className="footer-lines"/>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col className="mb-3 text-center" xs={4} md={12}>
              <img src={mavsignIcon} alt="mavsign logo" />
            </Col>
            <Col xs={5} md={12}>
              <div className="copyright">
                {new Date().getFullYear()} © Mavsign. Mav That Deal!
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <RequestDemoModal isOpen={isModalOpen} toggle={toggleModal} closeModal={() => setIsModalOpen(false)} />
    </React.Fragment>
  )
}

export default Footer;