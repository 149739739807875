import { FormMessageType } from "../Interfaces/base";

const successIcon: string = require('../assets/images/success-icon.svg').default;
const pendingIcon: string = require('../assets/images/pending-icon.svg').default;

export const successMessages: FormMessageType = {
  image: successIcon,
  title: 'Thank You for Your Interest!',
  description: `Your form has been successfully submitted. You’ll be contacted in short time.`
}

export const oneStepAwayMessages: FormMessageType = {
  image: successIcon,
  title: 'You\'re one step away!',
  description: 'An email will be sent shortly to complete your registration.',
}

export const pendingMessages:FormMessageType = {
  image: pendingIcon,
  title: 'Registration is pending',
  description: 'Your information has been sent. An account representative will check them and send you an email with the next steps to log in.',
}