import React from "react";
import { IInfoMessage } from "../../Interfaces/base";

const infoIcon: string = require("../../assets/images/blue-info-icon.svg").default;

const InfoMessage = ({ description }: IInfoMessage) => {

  return(<div className="request-demo-info w-100">
    <img src={infoIcon} className="me-2" />
    <div dangerouslySetInnerHTML={{ __html: description }} />
  </div>)
}

export default InfoMessage;