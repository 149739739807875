import React from "react";
import { Modal } from "reactstrap";
import { ITitleDescription } from "../../Interfaces/base";

interface FormMessageProps extends ITitleDescription {
  image: string;
	isOpen: boolean;
  closeModal: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

const FormMessage = ({isOpen, closeModal, image, title, description}: FormMessageProps) => {
  return <Modal className="custom-modal form-message" isOpen={isOpen} centered fade={true}>
    <div className="custom-modal-header">
      <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={closeModal} />
      <img src={image} className="form-message-image mb-4" alt="form-message" />
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
    </div>
  </Modal>
}

export default FormMessage;